import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { EMPTY_CARD, useCards } from "../../contexts/CardsContext";
import CardsAmount from "./CardsAmount/CardsAmount";
import PaymentSplit from "./PaymentSplit/PaymentSplit";
import { getUserDetails } from "../../services/authService";
import { payLds } from "../../services/fhpService";
import { Loader } from "../../Components/Loader/Loader";
import { useSelectedResult } from "../../contexts/SelectedResultContext";
import { round2 } from "../../contexts/AppContext";
import { message } from "antd";

const errorMessages = {
  1: "חסום החרם כרטיס",
  2: "גנוב החרם כרטיס",
  3: "התקשר לחברת האשראי",
  4: "סירוב",
  5: "מזויף החרם כרטיס",
  6: "תז או CVV שגויים",
  7: "חובה להתקשר לחברת האשראי",
  8: "תקלה בבניית מפתח גישה לקובץ חסומים",
  9: "לא הצליח להתקשר, התקשר לחברת האשראי",
  10: 'תוכנית הופסקה עפ"י הוראת המפעיל (ESC) או COM PORT לא ניתן לפתיחה (WINDOWS)',
  15: "אין התאמה בין המספר שהוקלד לפס המגנטי",
  17: "לא הוקלדו 4 ספרות האחרונות",
  19: "רשומה בקובץ INT_IN קצרה מ",
  20: "קובץ קלט (IN_INT) לא קיים",
  21: "קובץ חסומים (NEG) לא קיים או לא מעודכן",
  22: "אחד מקבצי פרמטרים או ווקטורים לא קיים",
  23: "קובץ תאריכים (DATA) לא קיים",
  24: "קובץ אתחול (START) לא קיים",
  25: "הפרש בימים בקליטת חסומים גדול מדי",
  26: "הפרש דורות בקליטת חסומים גדול מידי – בצע שידור או בקשה לאישור עבור כל עסקה",
  27: "כאשר לא הוכנס פס מגנטי כולו הגדר עסקה כעסקה טלפונית או כעסקת חתימה בלבד",
  28: "מספר מסוף מרכזי לא הוכנס למסוף המוגדר לעבודה כרב ספק",
  29: "מספר מוטב לא הוכנס למסוף המוגדר לעבודה כרב מוטב",
  30: "מסוף שאינו מעודכן כרב ספק/רב מוטב והוקלד מס' ספק/מס' מוטב",
  31: "מסוף מעודכן כרב ספק והוקלד גם מס' מוטב",
  32: "תנועות ישנות בצע שידור או בקשה לאישור עבור כל עסקה",
  33: "כרטיס לא תקין",
  34: "כרטיס לא רשאי לבצע במסוף זה או אין אישור לעסקה כזאת",
  35: "כרטיס לא רשאי לבצע עסקה עם סוג אשראי זה",
  36: "פג תוקף",
  37: "שגיאה בתשלומים",
  38: "לא ניתן לבצע עסקה מעל תקרה לכרטיס לאשראי חיוב מיידי",
  39: "ספרת ביקורת לא תקינה",
  40: "מסוף שמוגדר כרב מוטב הוקלד מס' ספק",
  41: "מעל תקרה, אך קובץ מכיל הוראה לא לבצע שאילתא (J1,J2,J3)",
  42: "חסום בספק, אך קובץ הקלט מכיל הוראה לא לבצע שאילתא (J1,J2,J3)",
  43: "אקראית, אך קובץ הקלט מכיל הוראה לא לבצע שאילתא (J1,J2,J3)",
  44: "מסוף לא רשאי לבקש אישור ללא עסקה, אך קובץ הקלט מכיל (5J)",
  45: "מסוף לא רשאי לבקש אישור ביוזמתו, אך קובץ הקלט מכיל (6J)",
  46: "יש לבקש אישור, אך קובץ הקלט מכיל הוראה לא לבצע שאילתא (J1,J2,J3)",
  47: 'יש לבקש אישור בשל בעיה הקשורה לקכ"ח אך קובץ הקלט מכיל הוראה לא לבצע שאילתא',
  51: "מספר רכב לא תקין",
  52: "מד מרחק לא הוקלד",
  53: "מסוף לא מוגדר כתחנת דלק (הועבר כרטיס דלק או קוד עסקה לא מתאים)",
  57: "לא הוקלד מספר תעודת זהות",
  58: "לא הוקלד CVV2",
  59: "לא הוקלדו מספר תעודת הזהות וה",
  60: "צרוף ABS לא נמצא בהתחלת נתוני קלט בזיכרון",
  61: "מספר כרטיס לא נמצא או נמצא פעמיים",
  62: "סוג עסקה לא תקין",
  63: "קוד עסקה לא תקין",
  64: "סוג אשראי לא תקין",
  65: "מטבע לא תקין",
  66: "קיים תשלום ראשון ו/או תשלום קבוע לסוג אשראי שונה מתשלומים",
  67: "קיים מספר תשלומים לסוג אשראי שאינו דורש זה",
  68: "לא ניתן להצמיד לדולר או למדד לסוג אשראי שונה מתשלומים",
  69: "אורך הפס המגנטי קצר מידי",
  70: "לא מוגדר מכשיר להקשת מספר סודי",
  71: "חובה להקליד מספר סודי",
  72: 'קכ"ח לא זמין – העבר בקורא מגנטי',
  73: 'הכרטיס נושא שבב ויש להעבירו דרך הקכ"ח',
  74: "דחייה – כרטיס נעול",
  75: 'דחייה – פעולה עם קכ"ח לא הסתיימה בזמן הראוי',
  76: 'דחייה – נתונים אשר התקבלו מקכ"ח אינם מוגדרים במערכת',
  77: "הוקש מספר סודי שגוי",
  80: 'הוכנס "קוד מועדון" לסוג אשראי לא מתאים',
  99: "לא מצליח לקרוא/ לכתוב/ לפתוח קובץ TRAN",
  101: "אין אישור מחברת אשראי לעבודה",
  106: "למסוף אין אישור לביצוע שאילתא לאשראי חיוב מיידי",
  107: "סכום העסקה גדול מידי – חלק למספר העסקאות",
  108: "למסוף אין אישור לבצע עסקאות מאולצות",
  109: "למסוף אין אישור לכרטיס עם קוד השרות 587",
  110: "למסוף אין אישור לכרטיס חיוב מיידי",
  111: "למסוף אין אישור לעסקה בתשלומים",
  112: "למסוף אין אישור לעסקה טלפון/ חתימה בלבד בתשלומים",
  113: "למסוף אין אישור לעסקה טלפונית",
  114: 'למסוף אין אישור לעסקה "חתימה בלבד"',
  115: "למסוף אין אישור לעסקה בדולרים",
  116: "למסוף אין אישור לעסקת מועדון",
  117: "למסוף אין אישור לעסקת כוכבים/נקודות/מיילים",
  118: "למסוף אין אישור לאשראי ישראקרדיט",
  119: "למסוף אין אישור לאשראי אמקס קרדיט",
  120: "למסוף אין אישור להצמדה לדולר",
  121: "למסוף אין אישור להצמדה למדד",
  122: 'למסוף אין אישור להצמדה למדד לכרטיסי חו"ל',
  123: "למסוף אין אישור לעסקת כוכבים/נקודות/מיילים לסוג אשראי זה",
  124: "למסוף אין אישור לאשראי קרדיט בתשלומים לכרטיסי ישראכרט",
  125: "למסוף איו אישור לאשראי קרדיט בתשלומים לכרטיסי אמקס",
  126: "למסוף אין אישור לקוד מועדון זה",
  127: "למסוף אין אישור לעסקת חיוב מיידי פרט לכרטיסי חיוב מיידי",
  128: "למסוף אין אישור לקבל כרטיסי ויזה אשר מתחילים ב",
  129: "למסוף אין אישור לבצע עסקת זכות מעל תקרה",
  130: "כרטיס לא רשאי לבצע עסקת מועדון",
  131: "כרטיס לא רשאי לבצע עסקת כוכבים/נקודות/מיילים",
  132: "כרטיס לא רשאי לבצע עסקאות בדולרים (רגילות או טלפוניות)",
  133: "כרטיס לא תקף על פי רשימת כרטיסים תקפים של ישראכרט",
  134: "כרטיס לא תקין עפ”י הגדרת המערכת (VECTOR1 של ישראכרט)",
  135: "כרטיס לא רשאי לבצע עסקאות דולריות עפ”י הגדרת המערכת (VECTOR1 של ישראכרט)",
  136: "הכרטיס שייך לקבוצת כרטיסים אשר אינה רשאית לבצע עסקאות עפ”י הגדרת המערכת (VECTOR20 של ויזה)",
  137: "קידומת הכרטיס (7 ספרות) לא תקפה עפ”י הגדרת המערכת (21VECTOR של דיינרס)",
  138: "כרטיס לא רשאי לבצע עסקאות בתשלומים על פי רשימת כרטיסים תקפים של ישראכרט",
  139: "מספר תשלומים גדול מידי על פי רשימת כרטיסים תקפים של ישראכרט",
  140: "כרטיסי ויזה ודיינרס לא רשאים לבצע עסקאות מועדון בתשלומים",
  141: "סידרת כרטיסים לא תקפה עפ”י הגדרת המערכת (VECTOR5 של ישראכרט)",
  142: "קוד שרות לא תקף עפ”י הגדרת המערכת (VECTOR6 של ישראכרט)",
  143: "קידומת הכרטיס (2 ספרות) לא תקפה עפ”י הגדרת המערכת (VECTOR7 של ישראכרט)",
  144: "קוד שרות לא תקף עפ”י הגדרת המערכת (VECTOR12 של ויזה)",
  145: "קוד שרות לא תקף עפ”י הגדרת המערכת (VECTOR13 של ויזה)",
  146: "לכרטיס חיוב מיידי אסור לבצע עסקת זכות",
  147: 'כרטיס לא רשאי לבצע עסקאות בתשלומים עפ"י וקטור 31 של לאומיקארד',
  148: 'כרטיס לא רשאי לבצע עסקאות טלפוניות וחתימה בלבד עפ"י ווקטור 31 של לאומיקארד',
  149: 'כרטיס אינו רשאי לבצע עסקאות טלפוניות עפ"י וקטור 31 של לאומיקארד',
  150: "אשראי לא מאושר לכרטיסי חיוב מיידי",
  151: 'אשראי לא מאושר לכרטיסי חו"ל',
  152: "קוד מועדון לא תקין",
  153: 'כרטיס לא רשאי לבצע עסקאות אשראי גמיש (עדיף +30/) עפ"י הגדרת המערכת (21VECTOR של דיינרס)',
  154: 'כרטיס לא רשאי לבצע עסקאות חיוב מיידי עפ"י הגדרת המערכת (VECTOR21 של דיינרס)',
  155: "סכום המינמלי לתשלום בעסקת קרדיט קטן מידי",
  156: "מספר תשלומים לעסקת קרדיט לא תקין",
  157: "תקרה 0 לסוג כרטיס זה בעסקה עם אשראי רגיל או קרדיט",
  158: "תקרה 0 לסוג כרטיס זה בעסקה עם אשראי חיוב מיידי",
  159: "תקרה 0 לסוג כרטיס זה בעסקת חיוב מיידי בדולרים",
  160: "תקרה 0 לסוג כרטיס זה בעסקה טלפונית",
  161: "תקרה 0 לסוג כרטיס זה בעסקת זכות",
  162: "תקרה 0 לסוג כרטיס זה בעסקת תשלומים",
  163: 'כרטיס אמריקן אקספרס אשר הונפק בחו"ל לא רשאי לבצע עסקאות תשלומים',
  164: "כרטיסיJCB רשאי לבצע עסקאות רק באשראי רגיל",
  165: "סכום בכוכבים/נקודות/מיילים גדול מסכום העסקה",
  166: "כרטיס מועדון לא בתחום של המסוף",
  167: "לא ניתן לבצע עסקת כוכבים/נקודות/מיילים בדולרים",
  168: "למסוף אין אישור לעסקה דולרית עם סוג אשראי זה",
  169: "לא ניתן לבצע עסקת זכות עם אשראי שונה מהרגיל",
  170: "סכום הנחה בכוכבים/נקודות/מיילים גדול מהמותר",
  171: "לא ניתן לבצע עסקה מאולצת לכרטיס/אשראי חיוב מיידי",
  172: "לא ניתן לבטל עסקה קודמת (עסקת זכות או מספר כרטיס אינו זהה)",
  173: "עסקה כפולה",
  174: "למסוף אין אישור להצמדה למדד לאשראי זה",
  175: "למסוף אין אישור להצמדה לדולר לאשראי זה",
  176: "כרטיס אינו תקף עפ”י הגדרת המערכת (וקטור 1 של ישראכרט)",
  177: 'בתחנות דלק לא ניתן לבצע "שרות עצמי" אלא "שרות עצמי בתחנות דלק"',
  178: "אסור לבצע עסקת זכות בכוכבים/נקודות/מיילים",
  179: "אסור לבצע עסקת זכות בדולר בכרטיס תייר",
  180: "בכרטיס מועדון לא ניתן לבצע עסקה טלפונית",
  200: "שגיאה יישומית",
};

const Payment = ({
  our_order_id,
  leftToPay,
  sumpaid,
  cardsAmount,
  setCardsAmount,
  isTour = false,
}) => {
  const { cards, updateCards } = useCards();

  const { t } = useTranslation();
  const { setOurSavedLDSOrder, ourPrice } = useSelectedResult();

  const handleCardsAmountChange = (newCardsAmount) => {
    if (!isNaN(newCardsAmount) && newCardsAmount >= 1) {
      setCardsAmount(newCardsAmount);
      let tempCards = [...cards];

      if (cards.length > newCardsAmount) {
        tempCards = cards.slice(0, newCardsAmount);
      } else {
        for (let i = cards.length; i < newCardsAmount; i++) {
          tempCards.push(EMPTY_CARD);
        }
      }
      updateCards(tempCards);
    } else {
      console.error("Invalid input, resetting to default card");
      updateCards([EMPTY_CARD]);
    }
  };

  function pay(card) {
    const {
      cardholdername,
      cardnumber,
      expdate,
      cvv,
      cardholderid,
      numberofpayments,
    } = card;
    const { email, id, firstname, lastname } = getUserDetails();

    const transactionData = {
      info: JSON.stringify({
        order_id: our_order_id,
        agent: {
          id,
          firstname,
          lastname,
        },
      }),
      amount: round2(card.amountWithInterest), // TOTAL TO PAY
      tashFees: round2(card.amountWithInterest - card.amount),
      userid: cardholderid, // TEUDAT ZEHOOT
      firstname: cardholdername.split(" ")[0],
      lastname: cardholdername.split(" ")[1],
      email: email,
      tash: numberofpayments, // amount of tashloomim
      currency: "ILS", // ILS | USD | EUR
      cc: cardnumber.replace(/\s/g, ""),
      month: expdate.split("/")[0], // IMPORTANT TO BE IN THIS FORMAT: MM
      year: "20" + expdate.split("/")[1], // IMPORTANT TO BE IN THIS FORMAT: YYYY
      cvv: cvv,
      ourLdsOrderId: our_order_id,
      ourPrice,
    };
    return payLds(transactionData)
      .then((res) => {
        if (res.status === 200)
          if (res.data) {
            setOurSavedLDSOrder(res.data);
            return true;
          }
        return false;
      })
      .catch((err) => {
        if (err.response?.data?.exception) {
          const errorCode = Number(
            err.response?.data?.exception.split(" ")[-1]
          );
          if (errorCode in errorMessages) {
            message.error(errorMessages[errorCode]);
          } else {
            message.error("אירעה שגיאה בתשלום");
          }
        } else if (err.response?.data?.error)
          message.error(err.response?.data?.error);
        return false;
      })
      .catch((err) => {
        message.error("אירעה שגיאה בתשלום");
        return false;
      });
  }

  const renderTotalPays = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirections: "row",
          justifyContent: "space-around",
        }}
      >
        <h2
          className="text-header"
          style={{ display: "flex", flexDirection: "row" }}
        >
          {t("packages.paid")}: ₪
          <span style={{ color: "#25ac03" }}>{sumpaid}</span>
        </h2>
        <h2
          className="text-header"
          style={{ display: "flex", flexDirection: "row" }}
        >
          יתרה: ₪
          <span style={{ color: "red" }}>
            {leftToPay ? leftToPay : <Loader />}
          </span>
        </h2>
      </div>
    );
  };

  return (
    <>
      <div
        className="lg:tw-w-[700px]"
        // style={{ width: 700 }}
      >
        {renderTotalPays()}

        <div style={{ padding: "10px 20px", border: "1px solid black" }}>
          <CardsAmount
            amount={cardsAmount}
            setAmount={handleCardsAmountChange}
          />
        </div>
        <div style={{ padding: 20, border: "1px solid black" }}>
          <PaymentSplit
            handlingFee={1}
            commission={1}
            priceToPay={leftToPay}
            setPriceToPay={console.log}
            setCardPaid={console.log}
            pay={pay}
          />
        </div>
      </div>
    </>
    // </CardsProvider>
  );
};

export default Payment;
