import React from "react";
import Rating from "@mui/material/Rating";
import { useTranslation } from "react-i18next";
import { OrangeButton } from "../../../buttons/Buttons";
import FlightsFooter from "./FlightsFooter";
import translateLDSDestinations from "../../../../services/translateLDSDestinations";
import NoResults from "../../../../assets/images/icons/no-results.png";
import Divider from "@mui/material/Divider";

import "./packageobject.scss";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../Routes/Routes";
import { useSelectedResult } from "../../../../contexts/SelectedResultContext";
import { Loader } from "../../../Loader/Loader";

import { WiTime8 } from "react-icons/wi";
import { MdOutlineDirectionsBus } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { useSearch } from "../../../../contexts/SearchContext";
import { Tooltip } from "antd/lib";
import infoSymbol from "../../../../assets/images/icons/info.svg";

const PackageObject = ({ fullDeal, shortenedDeal }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSelectedResult_resetAllFields } = useSelectedResult();
  const { calculateLDSDealFareForRoomsComposition } = useSearch();

  const getBoardDetails = (LDSboard) => {
    switch (LDSboard) {
      case "BB":
        return t("boards.BB");
      case "HB":
        return t("boards.HB");
      case "FB":
        return t("boards.FB");
      case "AI":
        return t("boards.AI");
      case "RO":
        return t("boards.RO");
      default:
        return LDSboard;
    }
  };

  const handleClick = () => {
    setSelectedResult_resetAllFields(fullDeal);
    navigate(`/${ROUTES.PACKAGE_PROFILE}`);
  };

  const renderHotelData = () => {
    const { hotel, isTransferIncluded, supplierDetails, hotelAllocation } =
      fullDeal;

    const supplierId = supplierDetails?.supplierId;

    const ourPrice = calculateLDSDealFareForRoomsComposition(
      fullDeal,
      shortenedDeal
    );
    // fullDeal.ourPrice = ourPrice;

    const { ourTotalFare, ourAdultFare, ourChildFare, ourInfantFare } =
      ourPrice || {};

    const starRating = hotel?.category?.replace(/\D/g, "");
    const boardDetails = getBoardDetails(hotel?.board);
    const isAutoApprove = hotelAllocation === "true";
    const translatedName = translateLDSDestinations(hotel?.hotelCity);

    const ourChildFareView =
      ourChildFare?.map((childFare, childIndex) => {
        return (
          <>
            <br />
            {t("packages.childPrice") +
              " " +
              (ourChildFare.length > 1
                ? (childIndex + 1).toString() + " "
                : "")}
            {childFare.priceWithCommissionAndCreditCardFee ||
              ourAdultFare.priceWithCommissionAndCreditCardFee}
            ₪
          </>
        );
      }) || [];

    return (
      <div className="hotel-data lg:tw-flex tw-flex-col lg:tw-flex-row ">
        <img
          className="hotel-img"
          src={hotel?.resourceUrl || NoResults}
          alt={hotel?.hotelName}
        />
        <div className="center-hotel-data">
          <div className="hotel-name">
            {hotel?.hotelName}
            <Rating
              name="read-only"
              value={starRating}
              readOnly
              visuallyHidden
              size="small"
              emptyIcon={<></>}
            />
          </div>
          <div className="hotel-city">
            {translatedName.COUNTRYNAME_HEB} - {translatedName.CITYNAME_HEB}
          </div>
          <div className="text-with-icon tw-text-sm lg:tw-text-base">
            <SlCalender className="orange-icon" />
            <div className="text">
              {hotel?.checkInDate} - {hotel?.checkOutDate} | {boardDetails}
            </div>
          </div>

          <div className="text-with-icon tw-text-sm lg:tw-text-base">
            <WiTime8 className="orange-icon" />

            <div className="text">
              {hotel?.nights} {t("packages.nights")}
            </div>
          </div>

          {isTransferIncluded === "true" && (
            <div className="text-with-icon tw-text-sm lg:tw-text-base">
              <MdOutlineDirectionsBus className="orange-icon" />

              <div className="text">כולל העברות</div>
            </div>
          )}

          <div className="text-with-icon tw-text-sm lg:tw-text-base">
            <div className="text">
              {t("packages.supplierId") + ": " + supplierId}
            </div>
          </div>
        </div>
        <div className="price-data">
          {/* todo(sagiv): Add loader*/}
          <div className="total-price">
            {ourTotalFare?.priceWithCommissionAndCreditCardFee + "₪"}
          </div>
          <div
            className="total-title"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {t("packages.totalFare")}
            <Tooltip
              title={
                <div className="details">
                  {t("packages.passangerPrice")}{" "}
                  {ourAdultFare?.priceWithCommissionAndCreditCardFee}₪
                  {ourChildFareView}
                  {ourTotalFare?.priceWithCommissionAndCreditCardFee ? (
                    <br />
                  ) : (
                    ""
                  )}
                  {ourInfantFare?.priceWithCommissionAndCreditCardFee
                    ? t("packages.infantPrice") +
                      " " +
                      ourInfantFare?.priceWithCommissionAndCreditCardFee +
                      "₪"
                    : ""}
                </div>
              }
            >
              <img
                src={infoSymbol}
                alt="info"
                style={{
                  width: "15px",
                  height: "15px",
                  marginRight: "5px",
                }}
              />
            </Tooltip>
          </div>
          {isAutoApprove ? (
            <div className="auto-approve"> {t("packages.autoApprove")}</div>
          ) : (
            <div className="not-auto-approve">נדרש אישור של המלון</div>
          )}
          <div className="order-btn">
            <OrangeButton onClick={handleClick}>
              {t("packages.order")}
            </OrangeButton>
          </div>
        </div>
      </div>
    );
  };

  const renderFlights = () => {
    const { linkedFlights } = fullDeal;
    return <FlightsFooter flights={linkedFlights?.flight} />;
  };

  return (
    <div className="package-wrapper hover:tw-bg-slate-200">
      {!fullDeal ? (
        <Loader size="small" />
      ) : (
        <>
          {renderHotelData()}
          <Divider orientation="horizontal" flexItem />
          {renderFlights()}
        </>
      )}
    </div>
  );
};

export default PackageObject;
