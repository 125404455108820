import React, { useState, useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { BsChevronDown, BsChevronUp } from "react-icons/bs";
import { useTranslation } from "react-i18next";
import doneImg from "../../../assets/images/passangerData/done.png";
import CustomLoading from "../../GeneralComponents/CustomLoading/customloading";
import { convertPrice, parseNumber } from "../../../services/currencyService";
import {
  CardHolderId,
  CardHolderNameInput,
  CardNumberInput,
  CvvInput,
  ExpDateInput,
  PaymentsAmountInput,
} from "./CardPaymentInputs";
import PaymentInterest from "./PaymentInterest";
import PaymentIcons from "./PaymentIcons";
import { handleInputChange } from "../../../utils/PaymentHelpers";
import "./CardPayment.scss";
import CardPaidOverlay from "./CardPaidOverlay";
import { Loader } from "../../Loader/Loader";
import { useCards } from "../../../contexts/CardsContext";
import { message } from "antd";

const CardPayment = ({ priceToPay, cardIndex, pay }) => {
  const { cards, updateCards } = useCards();
  const { t } = useTranslation();
  const [currentError, setCurrentError] = useState(false);
  const [expandCard, setExpandCard] = useState(false);
  const [loading, setLoading] = useState(false);
  const currentCard = cards[cardIndex];

  const { paid: currentCardPaid } = currentCard;
  const cardDisabled = currentCardPaid;

  // 1. Auto open first card
  useEffect(() => {
    if (cardIndex === 0) {
      setExpandCard(true);
    }
  }, []);

  // --- Functions
  const toggleExpantion = () => {
    setExpandCard(!expandCard);
  };

  // Mark and un mark inputs
  const handleMarkEdit = (e) => e.target?.classList.add("ci-input-editing");

  const handleUnMarkEdit = (e) =>
    e.target?.classList.remove("ci-input-editing");

  // Save changes for inputs
  const onInputChange = (e) => {
    handleInputChange(e, cards, updateCards, cardIndex);
  };

  const handlePaymentsChange = (e) => {
    const newPaymentsAmount = parseInt(e.target.value);
    let tempCards = cards;
    tempCards[cardIndex].numberofpayments = newPaymentsAmount;
    updateCards(tempCards);
  };

  const handlePayment = async (e) => {
    if (
      currentCard.cardnumber === "" ||
      currentCard.expdate === "" ||
      currentCard.cvv === "" ||
      currentCard.cardholderid === "" ||
      currentCard.cardholdername === ""
    ) {
      message.error("אנא מלא את כל השדות");
      return;
    }
    setCurrentError(false);
    setLoading(true);
    pay(currentCard)
      .then((res) => {
        if (res) {
          let tempCards = JSON.parse(JSON.stringify(cards));
          tempCards[cardIndex].paid = true;
          updateCards(tempCards);
        }
      })
      .finally(() => {
        setLoading(false);
      });
    // setSubmitLoading(true);
  };

  const renderExpandedCard = () => {
    const defaultProps = {
      handleMarkEdit,
      handleUnMarkEdit,
      handleInputChange: onInputChange,
      disabled: cardDisabled,
    };
    return (
      <div
        className="payment-card-details tw-p-5  tw-flex tw-flex-col tw-gap-[10px]"
        style={{ position: "relative" }}
      >
        {currentCardPaid ? <CardPaidOverlay /> : null}
        {/* Card Cataloger */}
        {/*<div className="splitPayment-card-displayer-item">*/}
        {/*<form*/}
        {/*  id={`new-card-number-${cardIndex}`}*/}
        {/*  // onSubmit={handlePayment}*/}
        {/*  className={`splitPayment-card`}*/}
        {/*>*/}
        {/* Left to pay */}
        <div className="ci-ltp">
          <label className="ci-ltp-title">{t("paymentagent.lefttopay")}:</label>
          <label id="price-leftToPay" className="ci-ltp-value">
            {convertPrice(parseFloat(priceToPay))}
          </label>
        </div>

        {/* Amount & Num of payments */}
        <PaymentsAmountInput
          {...defaultProps}
          value={parseNumber(currentCard?.amount || 0)}
          handlePaymentsChange={handlePaymentsChange}
          totalAmountWithPaymentsFee={currentCard.amountWithInterest}
          numberofpayments={currentCard.numberofpayments}
        />

        {currentCard.numberofpayments > 1 ? (
          <div className="ci-a-nop">
            <PaymentInterest currentCard={currentCard} />
          </div>
        ) : null}

        <CardNumberInput
          {...defaultProps}
          value={currentCard.cardnumber}
          index={cardIndex}
        />

        <div className="ci-nd">
          <ExpDateInput {...defaultProps} value={currentCard.expdate} />

          <CardHolderNameInput
            value={currentCard.cardholdername}
            {...defaultProps}
          />
        </div>

        {/* CVV & ID */}
        <div style={{ display: "flex", flexDirection: "row" }}>
          <CvvInput {...defaultProps} value={currentCard.cvv} />

          <CardHolderId {...defaultProps} value={currentCard.cardholderid} />
        </div>

        {/* Submit button */}
        <div className="ci-submit">
          {!loading ? (
            <button
              onClick={handlePayment}
              className="new-credit-card-checkout-btn"
            >
              {t("paymentagent.payment")}
            </button>
          ) : (
            <Loader size="small" />
          )}
        </div>

        {/* Pics */}
        <PaymentIcons />

        {/* Error / Success messege */}
        <div className="ci-messeger">
          {/*<label*/}
          {/*  id={`messege-${cardIndex}-suc`}*/}
          {/*  className="ci-messeger-success"*/}
          {/*>*/}
          {/*  כרטיס האשראי עבר בהצלחה*/}
          {/*</label>*/}
          {!currentError ? null : (
            <label className="payment-error">
              <div> כרטיס האשראי לא עבר</div>
              <div>({currentError})</div>
            </label>
          )}
        </div>
        {/*</form>*/}
        {/*</div>*/}
      </div>
    );
  };

  return (
    <>
      <div className="splitPayment-wrapper">
        <div
          className="splitPayment-card-extand-wrapper"
          onClick={toggleExpantion}
        >
          <div className="splitPayment-card-extand-item">
            <img className="splitPayment-card-extand-img" src={doneImg}></img>
            <span className="splitPayment-card-extand-title">
              {t("paymentagent.cc")}: {cardIndex + 1}
            </span>
          </div>

          <div className="credit-card-left-topay-wrapper">
            <div
              className="credit-card-left-topay"
              style={{ opacity: currentCardPaid ? "1" : "0" }}
            >
              <label>סה"כ שולם:</label>
              <label>{currentCard.amountWithInterest}</label>
            </div>

            <div className="splitPayment-card-extand-item">
              {!expandCard ? (
                <BsChevronDown></BsChevronDown>
              ) : (
                <BsChevronUp></BsChevronUp>
              )}
            </div>
          </div>
        </div>

        {/* Card Shower */}
        {expandCard && renderExpandedCard()}
      </div>
    </>
  );
};

export default CardPayment;
