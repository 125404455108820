export function filterByLanguage(data, requestedLang) {
  if (!Array.isArray(data)) {
    return data;
  }
  return data?.find((item) => item["@localeLang"] === requestedLang) || null;
}

export function get_depret_from_result(result) {
  const { linkedFlights } = result;
  const depDate = linkedFlights?.flight[0]?.leg?.depDate;
  const retDate = linkedFlights?.flight[1]?.leg?.depDate;
  return { depDate, retDate };
}

export function parseToLdsDate(dateString) {
  const datePart = dateString.split(" ")[0];
  const [day, month, year] = datePart.split("/");
  return `${day}/${month}/${year}`;
}

export function countAdultsAndKids(roomsAndPaxInfo) {
  let totalAdults = 0;
  let totalKids = 0;
  let totalInfant = 0;

  if (roomsAndPaxInfo) {
    for (const group of roomsAndPaxInfo) {
      totalAdults += group.adults.length;
      totalKids += group.kids.length;
      totalInfant += group.infants.length;
      // // NOTE: LDS search only by the first room - there no counting
      break;
    }
  }

  return { totalAdults, totalKids, totalInfant };
}

export function getRoomSetByCount(adults, kids) {
  if (adults === 1 && kids > 0) {
    adults = 2;
    kids = kids - 1;
  }
  const key = "A" + adults + (kids > 0 ? "C" + kids : "");
  return key || null;
}

export function isLdsResponseEmpty(res) {
  return (
    res?.opStatus?.status === "NO_RESULTS" ||
    res?.data?.opStatus?.status === "NO_RESULTS" ||
    !res?.data
  );
}
