import React, { useState, useEffect } from "react";
import "./OrganizedTourProfile.scss";
import "../Profiles.scss";
import { useSelectedResult } from "../../../contexts/SelectedResultContext";
import {
  createLdsNewOrder,
  getOrgTourInfo,
  updateLdsOrder,
  findExtendedDeals,
} from "../../../services/fhpService";
import Stages from "../../../Components/Stages/Stages";
import PackageDetails from "./PackageDetails/PackageDetails";
// import PassengersData from "../../../Components/PassengersData/PassengersData";
import PackagePayment from "./PackagePayment/PackagePayment";
import SummaryFooter from "../../../Components/InfoBox/SummaryFooter";
import { getUserDetails } from "../../../services/authService";
import {
  // calculateTotalPayment,
  get_depret_from_result,
  parseToLdsDate,
} from "../../../utils/LDSHelpers";
import { useNavigate, useParams } from "react-router-dom";
// import { Loader } from "../../../Components/Loader/Loader";
import PackageOrderSummary from "../../../Components/Agent/SearchAgent/PackageObject/PackageOrderSummary";
import Timer from "../../Timer/Timer";

import {
  getTotalFeesCount,
  getTotalSumPaid,
} from "../../../utils/PaymentHelpers";
import { CardsProvider } from "../../../contexts/CardsContext";
// import { useOrder } from "../../../contexts/OrderContext";
import { round2 } from "../../../contexts/AppContext";

const OrganizedTourProfile = ({ orderSaved = false, ourLdsId }) => {
  const {
    currentStageIndex,
    setCurrentStageIndex,
    moveNextStage,
    selectedResult,
    fieldsFilled,
    roomsAndPaxInfo,
    ourSavedLDSOrder,
    isConditionsConfirmed,
    calculateLdsTotalPayment,
    ldsSupplement,
    ourPrice,
    setExtendedDealApiData,
    handleFeeData,
  } = useSelectedResult();

  const { id: our_order_id } = useParams();

  const [loading, setLoading] = useState(false);
  const [resourceURL, setResourceURL] = useState(null);

  const navigate = useNavigate();
  const user = getUserDetails();

  const { tashfees: tashFees, sumpaid, paid } = ourSavedLDSOrder || {};
  const tashfeesCount = getTotalFeesCount(tashFees);
  const {
    ldsDealId,
    dealId,
    linkedFlights,
    supplierDetails,
    fareDetails,
    dealFare,
  } = selectedResult || {};
  const { supplierId } = supplierDetails || {};
  // const { hotelCity, hotelName, hotelId } = hotel;
  // const { ldsDealId } = linkedFlights;

  const { currency } = fareDetails || {};

  const totalSumPaid = getTotalSumPaid(sumpaid, tashfeesCount);

  const { depDate, retDate } = selectedResult
    ? get_depret_from_result(selectedResult)
    : {};

  const insertLdsNewOrder = () => {
    createCustomerPriceQuote({ moveNextStateFlag: true });
  };

  const createCustomerPriceQuote = async ({ moveNextStateFlag = false }) => {
    setLoading(true);
    const { id } = user;

    const body = {
      tour: "true",
      agent_id: id,
      commission: ourPrice.agentCommission,
      handling_fee: ourPrice.handleFee,
      currency: currency || "USD",
      deal_id: dealId,
      supplierId: supplierId,
      passengers_info: roomsAndPaxInfo.map((room) => {
        return { ...room, room: selectedResult.tour.room };
      }),
      ret_date: parseToLdsDate(retDate),
      dep_date: parseToLdsDate(depDate),
      supplement: ldsSupplement,
      ourPrice: {
        ...ourPrice,
        handleFeeData: handleFeeData,
        base: {
          fareDetails,
          dealFare,
        },
        handleFee: ourPrice.handleFee,
      },
      linkedFlights: linkedFlights,
    };
    if (ourLdsId) {
      body.ourLdsId = ourLdsId;
      return updateLdsOrder(body).finally(() => {
        setLoading(false);
      });
    } else {
      return createLdsNewOrder(body)
        .then((res) => {
          const id = res?.data?.id;
          navigate(`${id}`);
          if (moveNextStateFlag) {
            moveNextStage();
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (paid) {
      setCurrentStageIndex(stages.length - 1);
    }
  }, [paid]);

  const leftToPay = ourPrice.priceWithCreditCardFee
    ? round2(ourPrice.priceWithCreditCardFee - totalSumPaid)
    : null;

  useEffect(() => {
    calculateLdsTotalPayment();
  }, [selectedResult]);

  useEffect(() => {
    if (!dealId) return;
    getOrgTourInfo(dealId).then((res) => {
      if (res.resourceUrl) {
        const resourceURL = res.resourceUrl;
        setResourceURL(resourceURL);
      } else {
        setResourceURL(null);
      }
    });
  }, [dealId]);

  useEffect(() => {
    if (!ldsDealId) return;
    _get_rooms_info();
  }, [ldsDealId]);

  function _get_rooms_info() {
    findExtendedDeals({
      lds_deal_id: ldsDealId,
    })
      .then((res) => {
        let extendedDeal = res?.data?.extendedDeal;
        if (extendedDeal && !Array.isArray(extendedDeal)) {
          extendedDeal = [extendedDeal];
        }
        setExtendedDealApiData(extendedDeal);
      })
      .catch((err) => console.log({ err }));
  }

  const stages = [
    {
      title: "פרטי הטיול",
      disabled: false,
      component: (
        <PackageDetails
          disabled={orderSaved}
          createCustomerPriceQuote={createCustomerPriceQuote}
          leftToPay={leftToPay}
          resourceURL={resourceURL}
        />
      ),
    },
    {
      title: "ביצוע תשלום",
      disabled: true,
      component: (
        <CardsProvider totalPrice={leftToPay}>
          <PackagePayment
            our_order_id={our_order_id}
            leftToPay={leftToPay}
            sumpaid={totalSumPaid}
            disabled={orderSaved}
          />
        </CardsProvider>
      ),
    },
    {
      title: "אישור וסיכום הזמנה",
      disabled: true,
      component: <PackageOrderSummary />,
    },
  ];

  const handleMoveNextStep = () => {
    switch (currentStageIndex) {
      case 0:
        if (fieldsFilled) {
          insertLdsNewOrder();
          moveNextStage();
        }
        break;
      case 1:
        if (orderSaved) {
          if (paid) moveNextStage();
        }
        break;
      case 3:
        // navigate("/p/dashboard/searchoffer");
        if (paid) navigate("/p/dashboard/orders");
        break;
      default:
        break;
    }
  };

  const isNextStageDisabled = () => {
    // return false;

    if (!isConditionsConfirmed) {
      return "יש לאשר את התקנון";
    }
    if (currentStageIndex === 0) {
      if (!fieldsFilled) {
        return "יש למלא את כל השדות";
      }
    } else if (currentStageIndex === 1) {
      if (!paid) {
        return "יש לשלם עבור כל ההזמנה";
      }
    }
    // else if (currentStageIndex === 2) {
    // }
    return false;
  };

  return (
    <div className={"packages-profile"}>
      <Timer />
      <Stages
        stages={stages}
        currentIndex={currentStageIndex}
        setCurrentStageIndex={setCurrentStageIndex}
        titleStyle={{ fontSize: 20 }}
        lineStyle={{ margin: "0 20px" }}
      />
      <div className="packages-profile-inner lg:tw-px-[15%] lg:tw-h-[80%] lg:tw-overflow-auto ">
        {stages[currentStageIndex].component}
      </div>
      <SummaryFooter
        nextStageDisabled={isNextStageDisabled()}
        moveNextStage={handleMoveNextStep}
        leftToPay={leftToPay}
        loading={loading}
      />
    </div>
  );
};

export default OrganizedTourProfile;
