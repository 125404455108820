import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import "./Filters.scss";
import { Dropdown, Input } from "antd";

const TextFilter = ({
  onFilterChange,
  filterName,
  placeHolder,
  value,
  searchResults,
}) => {
  const { t } = useTranslation();
  const [isExpand, setIsExpand] = useState(false);
  const [internalValue, setInternalValue] = useState(value);

  useEffect(() => {
    setInternalValue(value);
  }, [value]);

  const changeExpand = () => {
    setIsExpand((prevState) => !prevState);
    onFilterChange(internalValue);
  };

  const inputValue = internalValue ? internalValue : "";

  return (
    <div className="hotelFilters-filter-wrapper">
      <div
        onClick={changeExpand}
        className={`hotelFilters-filters-title-wrapper ${
          document.documentElement.dir === "ltr"
            ? "direction-rtl"
            : "direction-ltr"
        }`}
      >
        <span className="hotelFilters-filters-title">{filterName}</span>
        {isExpand ? (
          <IoIosArrowUp className="hotelFilters-filters-arrow" />
        ) : (
          <IoIosArrowDown className="hotelFilters-filters-arrow" />
        )}
      </div>

      {isExpand && (
        <div className="hotelfilters-price-wrapper">
          <Dropdown
            menu={{
              items: searchResults
                .map((item) => item.fullDeal.hotel.hotelName)
                .filter((item, index, self) => self.indexOf(item) === index)
                .map((itemName) => {
                  try {
                    const name = itemName.toLowerCase();
                    if (name.includes(inputValue.toLowerCase())) {
                      return {
                        key: name,
                        label: itemName,
                        onClick: () => {
                          setInternalValue(name);
                          onFilterChange(name);
                        },
                      };
                    }
                  } catch {
                    return null;
                  }
                })
                .filter((item) => item !== null),
            }}
            style={{ width: "100%" }}
          >
            <Input
              title={placeHolder}
              value={internalValue}
              onChange={(value) => {
                setInternalValue(value.target.value);
                onFilterChange(value.target.value);
              }}
            />
          </Dropdown>
        </div>
      )}
    </div>
  );
};

export default TextFilter;
