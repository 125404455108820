import React from "react";
import { useTranslation } from "react-i18next";
import { convertPrice } from "../../../../services/currencyService";

import SkeletonLoader from "../../../../Components/SkeletonLoader/SkeletonLoader";

import "./PaymentSummary.scss";
import { useSelectedResult } from "../../../../contexts/SelectedResultContext";
import { useAppContext } from "../../../../contexts/AppContext";

const PaymentSummary = () => {
  const { isAppLoggedIn } = useAppContext();
  const { ldsSupplement, ourPrice } = useSelectedResult();

  const { getCreditCardFeePercent, isGlobalConstantLoaded } = useAppContext();

  const { t } = useTranslation();

  const supplementSummary = () => {
    // Convert the dictionary to an array
    const supplementArray = Object.values(ldsSupplement);

    // Filter the array
    const filterSupp = supplementArray.filter((supp) => supp.checked);
    return filterSupp.map((s, index) => (
      <div key={index} className="ci-summery-fee1-wrapper">
        <label className="ci-summery-fee1-title">
          {t(s.type)} {s.weightText}
        </label>
        <label id="price-commission" className="ci-summery-fee0-value">
          {convertPrice(s.ourFare)}
        </label>
      </div>
    ));
  };

  const displayCreditCardFee = () => {
    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            margin: "4px 0",
          }}
        >
          <div className={"ci-summery-fee1-title"}>
            עמלת כרטיס ({getCreditCardFeePercent()}%)
          </div>
          <div className={"ci-summery-fee2-value"}>
            {convertPrice(ourPrice.creditCardFee || 0)}
          </div>
        </div>
      </>
    );
  };

  return (
    <>
      <div className="payment-summery-wrapper">
        <div className="payment-agent-price-payment-wrapper">
          <label>{t("ordersummery.ordersummery")}</label>
        </div>
        <div className="ci-summery-content-wrapper">
          {/* Fees */}
          <div className="ci-summery-fee1-wrapper">
            <label className="ci-summery-fee1-title">מחיר החבילה</label>
            <label id="price-commission" className="ci-summery-fee1-value">
              {!isAppLoggedIn()
                ? convertPrice(
                    ourPrice.priceWithCommission + ourPrice.handleFee
                  )
                : convertPrice(ourPrice.priceWithCommission)}
            </label>
          </div>
          {supplementSummary()}
          {isAppLoggedIn() && (
            <div className="ci-summery-fee1-wrapper">
              <label className="ci-summery-fee1-title">עמלת בסיס</label>
              <label id="price-commission" className="ci-summery-fee1-value">
                {convertPrice(ourPrice.agentCommission)}
              </label>
            </div>
          )}
          {isAppLoggedIn() && (
            <div className="ci-summery-fee1-wrapper">
              <label className="ci-summery-fee1-title">
                {t("paymentagent.handlingFee")}
              </label>

              <label id="price-commission" className="ci-summery-fee2-value">
                {!isGlobalConstantLoaded ? (
                  <SkeletonLoader height={10} style={{ width: 50 }} />
                ) : (
                  convertPrice(ourPrice.handleFee)
                )}
              </label>
            </div>
          )}
          {displayCreditCardFee()}
        </div>
        <div></div>
        <div className="ci-summery-border"></div>
        <div className="splitPayment-card-amount-totalprice ci-summery-topay-wrapper">
          <label className="splitPayment-card-amount-price-title ci-summery-topay">
            {t("paymentagent.priceis")}:
            <span
              id="priproblem-total"
              className="splitPayment-card-amount-price-number"
            >
              {convertPrice(ourPrice.priceWithCreditCardFee)}
            </span>
          </label>
        </div>
      </div>
    </>
  );
};

export default PaymentSummary;
