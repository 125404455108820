import React, { useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import FamilyIcon from "../../../../../assets/images/searchbars/family.png";
import "../../HotelSearchBar/hotelsearchbar.scss";
import RoomsCounter from "../../HotelSearchBar/RoomsCounter/roomscounter";
import { useOnClickOutside } from "../../../../../utils/ComponentsUtils";
import { parsePeopleCount } from "../../../../../contexts/SearchContext";

const PeopleCounterInput = ({ roomInfo, setRoomInfo, isTour = false }) => {
  const [open, setOpen] = useState(false);
  const [datePickerOpen, setDatePickerOpen] = useState(false);

  const ref = useRef(null);

  const { t } = useTranslation();
  const toggleOpen = () => setOpen(!open);

  useOnClickOutside(ref, () => {
    if (!datePickerOpen) {
      setOpen(false);
    }
  });

  const peopleCount = parsePeopleCount(roomInfo);

  return (
    <div className="hotelSearchBar-item" ref={ref}>
      <div
        className="hotelSearchBar-desc-wrapper cursor-pointer"
        onClick={toggleOpen}
      >
        <div className="hotelSearchBar-icon-wrapper">
          <img
            className="hotelSearchBar-icon2 cursor-pointer"
            src={FamilyIcon}
            alt="Rooms"
          ></img>
        </div>

        <div className="hotelSearchBar-label-wrapper cursor-pointer">
          <label className="hotelSearchBar-label1 cursor-pointer">
            {t("hotelsearchbar.rooms")}
          </label>

          <label
            className="hotelSearchBar-label2 cursor-pointer"
            style={{ display: "flex", flexDirection: "row", gap: 5 }}
          >
            {!isTour && (
              <span>
                {peopleCount.totalRooms} {t("hotelsearchbar.roomsnumber")}
              </span>
            )}
            <span>
              {peopleCount.totalPeople} {t("roomscounter.passengers")}
            </span>
          </label>
        </div>
      </div>

      {open && <RoomsCounter setDatePickerOpen={setDatePickerOpen} />}
    </div>
  );
};

export default PeopleCounterInput;
