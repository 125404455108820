import React from "react";
import { useTranslation } from "react-i18next";
import { useSearch } from "../../contexts/SearchContext";
import PassengerData from "./PassengerData";
import { useSelectedResult } from "../../contexts/SelectedResultContext";

export const PASSENGER_KEYS = {
  FIRSTNAME: "firstname",
  LASTNAME: "lastname",
  CELL: "cell",
  EMAIL: "email",
  AGE: "age",
  KIS_SEARCH_AGE: "kisSearchAge",
  TITLE: "title",
  // IS_KID: 'isKid'
};

export const ROOM_KEYS = {
  LDS_DEAL_ID: "ldsDealId",
  DEAL_FARE: "dealFare",
  OUR_FARE_DETAILS: "ourFareDetails",
  ROOM_SET: "roomSet",
  ROOM_TYPE: "roomType",
  SEQ_NUM: "@seqNum",
};

const PassengersData = ({ allDisabled, returnDate, isTour = false }) => {
  const { t } = useTranslation();
  const { roomsAndPaxInfo, setRoomsAndPaxInfo } = useSelectedResult();

  function handlePassengerInfoChange(roomIndex, newPassengerInfo) {
    let tempPassengerInfo = JSON.parse(JSON.stringify(roomsAndPaxInfo));
    tempPassengerInfo[roomIndex] = newPassengerInfo;
    setRoomsAndPaxInfo(tempPassengerInfo);
  }

  return (
    <div className="passengers-data">
      {/*/!* Room Displayer *!/*/}
      <div className="passengerData-room-pass-info-wrapper">
        {roomsAndPaxInfo?.map((passengerInfo, roomIndex) => (
          <PassengerData
            key={roomIndex}
            roomIndex={roomIndex}
            passengerInfo={passengerInfo}
            setPassengerInfo={(newPassengerInfo) =>
              handlePassengerInfoChange(roomIndex, newPassengerInfo)
            }
            allDisabled={allDisabled}
            roomName={`(${passengerInfo?.room?.roomSet}) ${passengerInfo?.room?.roomType}`}
            returnDate={returnDate}
            isTour={isTour}
          />
        )) || null}
      </div>
    </div>
  );
};

export default PassengersData;
