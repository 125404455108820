import React from "react";
import FlightFooterRow from "./FlightFooterRow";
import { useTranslation } from "react-i18next";
import Divider from "@mui/material/Divider";

import "./FlightsFooter.scss";

const FlightsFooter = ({ flights }) => {
  const { t } = useTranslation();
  if (!flights) {
    return <div>Error getting flights</div>;
  }
  return flights.map((flight, index) => (
    <>
      {Array.isArray(flight.leg) ? (
        flight.leg.map((leg, index) => (
          <FlightFooterRow
            key={index}
            flight={leg}
            title={
              index % 2 === 0
                ? t("packages.firstWayFlight")
                : t("packages.returnFlight")
            }
          />
        ))
      ) : (
        <FlightFooterRow
          key={index}
          flight={flight.leg}
          title={
            index % 2 === 0
              ? t("packages.firstWayFlight")
              : t("packages.returnFlight")
          }
        />
      )}
      <Divider orientation="horizontal" flexItem variant="middle" />
    </>
  ));
};

export default FlightsFooter;
