import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import LDS_AUTOCMPLETE_CITIES from "../../../../../assets/Data/LdsAirportsAutocomplete";
import { getActiveDestinations } from "../../../../../services/fhpService";

export const parseLocationToString = (l) =>
  l.AIRPORTCODE === ""
    ? `${l.COUNTRYNAME_HEB}`
    : `(${l.AIRPORTCODE}) ${l.COUNTRYNAME_HEB} - ${l.CITYNAME_HEB}`;

const AutoCompleteInput = ({
  saveLocationSelection,
  location = "",
  organized = false,
}) => {
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [currentValue, setCurrentValue] = useState(
    location ? parseLocationToString(location) : ""
  );
  const [cities, setCities] = useState(LDS_AUTOCMPLETE_CITIES);
  const [isOpen, setIsOpen] = useState(true);
  const { t } = useTranslation();
  const optionsRef = React.createRef();
  const inputRef = React.createRef();

  useEffect(() => {
    getActiveDestinations(organized).then((res) => {
      const cities = res
        .filter((city) =>
          organized
            ? city.dealType === "OrganizedTour"
            : city.dealType === "FlightsHotel"
        )
        .map((city) => {
          const hebCity = LDS_AUTOCMPLETE_CITIES.find(
            (ldsCity) => ldsCity.AIRPORTCODE === city.destCity
          );
          return {
            COUNTRYNAME_HEB: hebCity?.COUNTRYNAME_HEB || city.destCountryName,
            CITYNAME_HEB: hebCity?.CITYNAME_HEB || city.destCountryName,
            AIRPORTCODE: city.destCity,
            AIRPORTNAME: hebCity?.AIRPORTNAME || "",
            CITYCODE: city.destCity,
            CITYNAME: city.destCityName,
            COUNTRYCODE: hebCity?.COUNTRYCODE || "",
            COUNTRYNAME: city.destCountryName,
          };
        });

      const hebCities = cities.reduce((acc, city) => {
        if (!acc.find((c) => c.CITYCODE === city.CITYCODE)) {
          acc.push(city);
        }
        return acc;
      }, []);

      hebCities.unshift(LDS_AUTOCMPLETE_CITIES[0]);

      setCities(hebCities);
    });
  }, [organized]);

  function getOptions(newValue) {
    if (newValue.length <= 2) {
      setFilteredOptions([]);
    } else {
      const filteredOptions = cities.filter((city) =>
        Object.values(city).some((value) =>
          value
            ? value.toString().toLowerCase().includes(newValue.toLowerCase())
            : false
        )
      );
      setFilteredOptions(filteredOptions);
    }
  }

  function onChange(e) {
    const newValue = e.target.value;
    setCurrentValue(newValue);
    getOptions(newValue);
  }

  function onOptionSelect(e, option) {
    e.preventDefault();
    setCurrentValue(parseLocationToString(option));
    saveLocationSelection(option);
    setIsOpen(false);
  }

  function getLdsOptionRow(option) {
    if (option.CITYCODE === "ALL") {
      return (
        <div
          id={`lds-autocomplete-${option?.AIRPORTCODE}`}
          className="autoComplete-input-option-row"
          onClick={(e) => {
            onOptionSelect(e, option);
          }}
          key={"airport-" + option.AIRPORTCODE}
        >
          <div>{`${option.COUNTRYNAME_HEB} - ${option.COUNTRYNAME}`}</div>
        </div>
      );
    }
    return (
      <div
        id={`lds-autocomplete-${option?.AIRPORTCODE}`}
        className="autoComplete-input-option-row"
        onClick={(e) => {
          onOptionSelect(e, option);
        }}
        key={"airport-" + option.AIRPORTCODE}
      >
        {option?.COUNTRYNAME_HEB && option?.CITYNAME_HEB ? (
          <div>
            {`(${option.AIRPORTCODE}) ${option.COUNTRYNAME_HEB} - ${option.CITYNAME_HEB}`}
          </div>
        ) : null}
        <div>{`${option.CITYNAME} - ${option.COUNTRYNAME}`}</div>
      </div>
    );
  }

  React.useEffect(() => {
    function handleClickOutside(event) {
      if (
        inputRef.current &&
        !inputRef.current.contains(event.target) && // Check if click is outside input
        optionsRef.current &&
        !optionsRef.current.contains(event.target) // Check if click is not on the div
      ) {
        setIsOpen(false);
      }
    }

    // Add click event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Remove event listener on cleanup
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [inputRef, optionsRef]);

  return (
    <div className="autoComplete-input-box-wrapper">
      <input
        onChange={onChange}
        className="autoComplete-input-box"
        value={currentValue}
        placeholder={t("hotelsearchbar.selectdestination")}
        ref={inputRef}
        onFocus={() => setIsOpen(true)}
      />
      {isOpen ? (
        <div className="autoComplete-input-options" ref={optionsRef}>
          {filteredOptions?.map((option) => getLdsOptionRow(option))}
        </div>
      ) : null}
    </div>
  );
};

export default AutoCompleteInput;
