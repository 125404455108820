import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import GpsPinIcon from "../../../../../assets/images/searchbars/pin.png";
import AutoCompleteInput from "../../SubComponents/AutoCompleteInput/autocompleteinput";
import "../../HotelSearchBar/hotelsearchbar.scss";
import AutocompleteinputLDS, {
  parseLocationToString,
} from "../AutoCompleteInput/autocompleteinputLDS";

const DestinationInput = ({
  location,
  setLocation,
  isLdsInput,
  isOrganizedTour,
}) => {
  const [isMouseOverInput, setIsMouseOverInput] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const { t } = useTranslation();

  // Make location disappear
  const handleMouseEnter = () => {
    setIsMouseOverInput(true);
    setIsEditing(true); // For hover remove this and change the onclick to onmouse something
  };

  const handleMouseLeave = () => {
    const googleOutPutRemover = () => {
      // Get all elements with the specified classes
      const elementsToRemove = document.querySelectorAll(
        ".pac-container, .pac-logo, .hdpi"
      );

      // Remove each element
      elementsToRemove.forEach((element) => element.remove());
    };

    // Set editors for false - not editing currently
    if (!isEditing) {
      setIsMouseOverInput(false);
      setIsEditing(false);

      // Remove all google option boxes left that are not being used
      googleOutPutRemover();
    }
  };

  return (
    <div
      className="hotelSearchBar-item hotelSearchBar-input-location lg:tw-w-[30%]"
      onClick={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {isMouseOverInput ? (
        isLdsInput ? (
          <AutocompleteinputLDS
            saveLocationSelection={setLocation}
            organized={isOrganizedTour}
          />
        ) : (
          <AutoCompleteInput
            saveLocationSelection={setLocation}
            location={location}
            saveLocationEdit={setIsEditing}
            closeInput={setIsMouseOverInput}
          />
        )
      ) : (
        <div className="hotelSearchBar-desc-wrapper">
          <div className="hotelSearchBar-icon-wrapper">
            <img
              className="hotelSearchBar-icon"
              src={GpsPinIcon}
              alt="Gps pin from"
            ></img>
          </div>

          <div className="hotelSearchBar-label-wrapper">
            <span className="hotelSearchBar-label1">
              {t("hotelsearchbar.selectdestination")}
            </span>

            {location ? (
              <span className="hotelSearchBar-label2">
                {isLdsInput
                  ? parseLocationToString(location)
                  : location.fullname}
              </span>
            ) : (
              <div className="hotelSearchBar-location-required-wrapper">
                <span className="hotelSearchBar-label2">
                  {t("hotelsearchbar.selectdestinationdesc")}
                </span>
                <input className="hotelSearchBar-location-required" required />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default DestinationInput;
