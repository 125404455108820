import dayjs from "dayjs";

export function getAllDepartureDates(ldsDates) {
  if (!ldsDates) {
    return [];
  }
  const ldsDatesArr = Array.isArray(ldsDates) ? ldsDates : [ldsDates];
  const departureDates = new Set();
  ldsDatesArr.forEach((link) => link ? departureDates.add(link.depDate) : null);
  return Array.from(departureDates).sort();
}

export function getReturnDatesForDeparture(ldsDates, selectedDepDate) {
  if (!ldsDates || !selectedDepDate) {
    return [];
  }

  const ldsDatesArr = Array.isArray(ldsDates) ? ldsDates : [ldsDates];
  const returnDates = ldsDatesArr
    .filter((link) => link.depDate === selectedDepDate)
    .map((link) => link.retDate);
  return returnDates.sort();
}

export function getAvailableMonthOfDeparture(ldsDates) {
  if (!ldsDates) {
    return [];
  }

  const ldsDatesArr = Array.isArray(ldsDates) ? ldsDates : [ldsDates];
  const availableMonths = new Set();

  ldsDatesArr.sort((linka, linkb) => {
    return dayjs(linka.depDate, "DD/MM/YYYY").diff(dayjs(linkb.depDate, "DD/MM/YYYY"));
  }).forEach((link) => availableMonths.add(link.depDate.split("/")[1] + "/" + link.depDate.split("/")[2]));

  return Array.from(availableMonths);
}