import React, { useEffect, useRef } from "react";
// import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
// import { findHotelDealsById, getLdsOrder } from "../../../services/fhpService";
// import { Loader } from "../../../Components/Loader/Loader";
// import { countAdultsAndKids } from "../../../utils/LDSHelpers";
import { BsFillPersonFill } from "react-icons/bs";
// import ScreenCapture from "../../ScreenCapture/ScreenCapture";
import { BsCheckLg } from "react-icons/bs";
// import { convertPrice } from "../../../services/currencyService";

import "./OrderSummary.scss";

const OrderDetail = ({
  title,
  value,
  classDetail = "successOrder-content-details-detail-value",
}) => {
  return (
    <div className="orderDetail-component successOrder-content-details-detail">
      <div className="successOrder-content-details-detail-title-wrapper">
        <BsCheckLg className="successOrder-content-details-detail-title-icon" />
        <label className="successOrder-content-details-detail-title">
          {title}:
        </label>
      </div>

      <div className={`${classDetail}`}>{value}</div>
    </div>
  );
};

const OrderSummary = ({
  loading,
  hotelCountry,
  hotelname,
  hotelMainImage,
  fullname,
  details,
}) => {
  const { t } = useTranslation();

  const tableForScreenshot = useRef(null);

  return (
    <div className="successOrder-wrapper">
      <div className="successOrder-component">
        {!loading && (
          <>
            <div className="successOrder-header-wrapper">
              <div className="successOrder-header-title">
                <label>
                  {t("successorder.yourorderto")} {hotelCountry}{" "}
                  {t("successorder.isapproved")}
                </label>
              </div>

              <div className="successOrder-header-hotelname">
                <label>{hotelname}</label>
              </div>
            </div>

            <div className="successOrder-body-wrapper  lg:tw-flex">
              <div className="successOrder-body">
                {hotelMainImage ? (
                  <img
                    src={hotelMainImage}
                    className="successOrder-body-item-img"
                  ></img>
                ) : (
                  <div>Loading...</div>
                )}

                <div className="successOrder-body-item-gap"></div>

                {/* Order Content */}
                <div className="successOrder-content-wrapper">
                  {/* <ScreenCapture captureRef={tableForScreenshot} /> */}

                  {/* Details Header */}
                  <div className="successOrder-content-header-wrapper">
                    <div className="successOrder-content-header-title-wrapper">
                      <BsFillPersonFill className="successOrder-content-header-title-icon" />
                      <label className="successOrder-content-header-title">
                        {t("successorder.thankyou")}, {fullname}!
                      </label>
                    </div>

                    {/* <label className="successOrder-content-header-sub-title">
                      {t("successorder.emailsent")} :{" "}
                      <span className="successOrder-content-header-sub-title-email">
                        {email}
                      </span>
                    </label> */}
                  </div>

                  {/* Details Summeration  */}
                  <div
                    className="successOrder-content-details-wrapper"
                    ref={tableForScreenshot}
                  >
                    <label className="successOrder-content-details-title">
                      {t("successorder.ordersummery")}
                    </label>

                    {/* Details */}
                    <div className="successOrder-content-details">
                      {details?.map((detail, index) => (
                        <OrderDetail
                          key={index}
                          title={detail.title}
                          value={detail.value}
                          classDetail={detail.classDetail}
                        />
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default OrderSummary;
