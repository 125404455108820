import React from "react";
import "./MainPage.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Main(props) {
  const t = useTranslation().t;
  return (
    <div className="main-page">
      <span className="tw-text-3xl tw-font-bold lg:tw-text-5xl">
        ברוכים הבאים למערכת סוכני הנסיעות Hotels River
      </span>
      <br />
      <span className="tw-text-xl lg:tw-text-3xl">
        המערכת המתקדמת שלנו, נועדה להקל עליכם בניהול ובתכנון הנסיעות עבור
        לקוחותיכם. כאן תוכלו למצוא את כל הכלים והמשאבים הנדרשים ליצירת חוויות
        בלתי נשכחות ללקוחותיכם.
      </span>
      <span className="tw-text-xl lg:tw-text-3xl">
        מה המערכת מציעה? חיפוש חבילות ומלונות: גישה למידע עדכני על חבילות צ'רטר
        ומלונות מכל רחבי העולם, אפשרות להזמנת הפלגות ועוד.
      </span>
      <span className="tw-text-xl lg:tw-text-3xl">
        כלים לניהול הזמנות: מעקב נוח אחרי כל ההזמנות והלקוחות שלכם. ארנק דיגיטלי
        ומשיכת רווחים בקלות.
      </span>
      <span className="tw-text-xl lg:tw-text-3xl">
        תמיכה 24/7: שירות הלקוחות שלנו זמין תמיד לכל שאלה או בעיה שעלולה לצוץ
      </span>
      <span className="tw-text-xl lg:tw-text-3xl">
        הדרכות ומשאבים: גישה למדריכים, סרטונים ומפגשים שיסייעו לכם לשדרג את הידע
        המקצועי שלכם.
      </span>
      <span className="tw-text-xl lg:tw-text-3xl">
        אנו כאן כדי לתמוך בכם ולסייע לכם להצליח! אם יש לכם שאלות או הצעות, אל
        תהססו לפנות אלינו.
      </span>
      <div>
        <Link to="/registeragent">
          <button className="button-client">{t("navbar.joinasagent")}</button>
        </Link>
      </div>
    </div>
  );
}

export default Main;
