import React, { useState, useEffect } from "react";
import classNames from "classnames";
import "./Loader.scss";

export const Loader = ({ size = "medium", showText = false, loaderText }) => {
  const [text, setText] = useState("טוען..."); // Initial Hebrew text
  const milisecondBetweenText = 3000;
  const texts = [
    "ברגע זה פנינו לספקים ברחבי העולם",
    "סוכן יקר, אנחנו כבר איתך",
    "התחלנו לקבל מידע, ממשיכים לקבל מחירים...",
    "טוען מידע...",
    "לא לשכוח לעבור פעמיים על פרטי הדרכונים",
    "תודה על ההמתנה, מעריכים את זה",
    "עוד רגע המערכת מציגה מחירונים",
    "המערכת מעדכנת את המידע",
    "תודה על ההמתנה",
  ];

  useEffect(() => {
    if (showText) {
      let index = 0;
      const intervalId = setInterval(() => {
        index = (index + 1) % texts.length;
        setText(texts[index]);
      }, milisecondBetweenText);
      return () => clearInterval(intervalId);
    }
  }, []);

  return (
    <div className="loader-container">
      <div className={classNames("loader", size)} />
      {showText && <div className="loader-text">{text}</div>}
      {loaderText && <div className="loader-text">{loaderText}</div>}
    </div>
  );
};
