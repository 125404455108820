import React, { useState } from "react";
import Payment from "../../../../Components/Payment/Payment";
import PaymentSummary from "./PaymentSummary";
import { useCards } from "../../../../contexts/CardsContext";

const PackagePayment = ({
  our_order_id,
  leftToPay,
  sumpaid,
  disabled,
  ...props
}) => {
  const [cardsAmount, setCardsAmount] = React.useState(1);

  return (
    <div
      className="tw-flex tw-flex-col-reverse lg:tw-flex-row tw-gap-[5%]"
      // style={{ display: "flex", flexDirection: "row", gap: "5%" }}
    >
      <div
        className="lg:tw-w-[35%]"
        // style={{ width: "35%" }}
      >
        <PaymentSummary
          our_order_id={our_order_id}
          disabled={disabled}
          {...props}
        />
      </div>

      <Payment
        cardsAmount={cardsAmount}
        setCardsAmount={setCardsAmount}
        our_order_id={our_order_id}
        leftToPay={leftToPay}
        sumpaid={sumpaid}
      />
    </div>
  );
};

export default PackagePayment;
