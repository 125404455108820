import React from "react";
import { useTranslation } from "react-i18next";
import { OrangeButton } from "../../../buttons/Buttons";
import FlightsFooter from "./FlightsFooter";
import translateLDSDestinations from "../../../../services/translateLDSDestinations";
import NoResults from "../../../../assets/images/tour/tour-cover.jpg";
import Divider from "@mui/material/Divider";

import "./tourObject.scss";
import { useNavigate } from "react-router-dom";
import { ROUTES } from "../../../../Routes/Routes";
import { useSelectedResult } from "../../../../contexts/SelectedResultContext";
import { Loader } from "../../../Loader/Loader";

import { MdOutlineDirectionsBus } from "react-icons/md";
import { useSearch } from "../../../../contexts/SearchContext";
import { Tabs } from "antd";
import FlightFooterRow from "./FlightFooterRow";
import { Tooltip } from "antd/lib";
import infoSymbol from "../../../../assets/images/icons/info.svg";

const TourObject = (fullDeal) => {
  fullDeal = { ...fullDeal };

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setSelectedResult_resetAllFields } = useSelectedResult();
  const { calculateLDSDealFareForRoomsComposition } = useSearch();
  const [selectedDepart, setSelectedDepart] = React.useState(null);
  const [selectedReturn, setSelectedReturn] = React.useState(null);

  const handleClick = () => {
    if (
      selectedDepart !== null &&
      selectedReturn !== null &&
      Array.isArray(fullDeal.linkedFlights)
    )
      fullDeal.linkedFlights = fullDeal.linkedFlights.find((linkedFlight) => {
        return (
          linkedFlight.flight[0].leg.depDate === selectedDepart.depDate &&
          linkedFlight.flight[1].leg.depDate === selectedReturn.depDate
        );
      });
    setSelectedResult_resetAllFields(fullDeal);
    navigate(`/${ROUTES.TOURE_PROFILE}`);
  };

  const renderHotelData = () => {
    const { tour, isTransferIncluded, supplierDetails, hotelAllocation } =
      fullDeal;

    const supplierId = supplierDetails?.supplierId;
    // if (supplierId === "AYALA") debugger;

    const ourPrice = calculateLDSDealFareForRoomsComposition(fullDeal);
    fullDeal.ourPrice = ourPrice;

    const { ourTotalFare, ourAdultFare, ourChildFare, ourInfantFare } =
      ourPrice || {};

    // const starRating = hotel?.category?.replace(/\D/g, "");
    // const boardDetails = getBoardDetails(hotel?.board);
    const isAutoApprove = hotelAllocation === "true";
    const translatedNames = (
      Array.isArray(tour?.tourCities.val)
        ? tour?.tourCities.val
        : [tour?.tourCities.val]
    ).map((city) => translateLDSDestinations(city));

    const ourChildFareView =
      ourChildFare?.map((childFare, childIndex) => {
        return (
          <>
            <br />
            {t("packages.childPrice") +
              " " +
              (ourChildFare.length > 1
                ? (childIndex + 1).toString() + " "
                : "")}
            {childFare.priceWithCommissionAndCreditCardFee ||
              ourAdultFare.priceWithCommissionAndCreditCardFee}
            ₪
          </>
        );
      }) || [];

    return (
      <div className="hotel-data lg:tw-flex tw-flex-col lg:tw-flex-row ">
        <img
          className="hotel-img"
          src={tour?.resourceUrl || NoResults}
          alt="טיול מאורגן"
        />
        <div className="center-hotel-data">
          <div className="hotel-name">{tour?.tourName}</div>
          <div className="hotel-city">
            {translatedNames.map((translatedName) => (
              <div>
                {translatedName.COUNTRYNAME_HEB} - {translatedName.CITYNAME_HEB}
              </div>
            ))}
          </div>

          {isTransferIncluded === "true" && (
            <div className="text-with-icon tw-text-sm lg:tw-text-base">
              <MdOutlineDirectionsBus className="orange-icon" />

              <div className="text">כולל העברות</div>
            </div>
          )}

          <div className="text-with-icon tw-text-sm lg:tw-text-base">
            <div className="text">
              {t("packages.supplierId") + ": " + supplierId}
            </div>
          </div>
        </div>
        <div className="price-data">
          {/* todo(sagiv): Add loader*/}
          <div className="total-price">
            {ourTotalFare?.priceWithCommissionAndCreditCardFee + "₪"}{" "}
          </div>
          <div
            className="total-title"
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {t("packages.totalFare")}
            <Tooltip
              title={
                <div className="details">
                  {t("packages.passangerPrice")}{" "}
                  {ourAdultFare?.priceWithCommissionAndCreditCardFee}₪
                  {ourChildFareView}
                  {ourTotalFare?.priceWithCommissionAndCreditCardFee ? (
                    <br />
                  ) : (
                    ""
                  )}
                  {ourInfantFare?.priceWithCommissionAndCreditCardFee
                    ? t("packages.infantPrice") +
                      " " +
                      ourInfantFare?.priceWithCommissionAndCreditCardFee +
                      "₪"
                    : ""}
                </div>
              }
            >
              <img
                src={infoSymbol}
                alt="info"
                style={{
                  width: "15px",
                  height: "15px",
                  marginRight: "5px",
                }}
              />
            </Tooltip>
          </div>
          {isAutoApprove ? (
            <div className="auto-approve"> {t("packages.autoApprove")}</div>
          ) : (
            <div className="not-auto-approve">נדרש אישור של המלון</div>
          )}
          <div className="order-btn">
            <OrangeButton onClick={handleClick}>
              {t("packages.order")}
            </OrangeButton>
          </div>
        </div>
      </div>
    );
  };

  const renderFlights = () => {
    const { linkedFlights } = fullDeal;
    if (Array.isArray(linkedFlights)) {
      if (selectedDepart === null || selectedReturn === null) {
        setSelectedDepart(linkedFlights[0].flight[0].leg);
        setSelectedReturn(linkedFlights[0].flight[1].leg);
      }
      const departFlights = linkedFlights
        .map((linkedFlight, index) => {
          return linkedFlight.flight[0].leg;
        })
        .reduce((acc, curr) => {
          if (acc.findIndex((item) => item.depDate === curr.depDate) === -1) {
            acc.push(curr);
          }
          return acc;
        }, []);
      const returnFlights = linkedFlights
        .map((linkedFlight, index) => {
          return linkedFlight.flight[1].leg;
        })
        .reduce((acc, curr) => {
          if (acc.findIndex((item) => item.depDate === curr.depDate) === -1) {
            acc.push(curr);
          }
          return acc;
        }, []);

      const departs = departFlights.map((leg, index) => {
        return {
          label: leg.depDate.split(" ")[1],
          key: index,
          children: (
            <FlightFooterRow
              key={index}
              flight={leg}
              title={t("packages.firstWayFlight")}
            />
          ),
        };
      });

      const returns = returnFlights.map((leg, index) => {
        return {
          label: leg.depDate.split(" ")[1],
          key: index,
          children: (
            <FlightFooterRow
              key={index}
              flight={leg}
              title={t("packages.returnFlight")}
            />
          ),
        };
      });

      return (
        <>
          <div
            className="title"
            style={{
              lineHeight: "2rem",
              fontSize: "1.2rem",
              paddingTop: "1rem",
              paddingRight: "1rem",
            }}
          >
            בחר טיסות
          </div>
          <div
            className="title"
            style={{
              lineHeight: "2rem",
              fontSize: "1.2rem",
              paddingTop: "1rem",
              paddingRight: "1rem",
            }}
          >
            טיסה הלוך:
          </div>
          <Tabs
            key="departs"
            defaultActiveKey="1"
            items={departs}
            onChange={(key) => {
              const flight = departFlights[Number(key)];
              setSelectedDepart(flight);
            }}
          />
          <Divider orientation="horizontal" flexItem variant="middle" />
          <div
            className="title"
            style={{
              lineHeight: "2rem",
              fontSize: "1.2rem",
              paddingTop: "1rem",
              paddingRight: "1rem",
            }}
          >
            טיסה חזור:
          </div>
          <Tabs
            key="returns"
            defaultActiveKey="1"
            items={returns}
            onChange={(key) => {
              const flight = returnFlights[Number(key)];
              setSelectedReturn(flight);
            }}
          />
        </>
      );
    } else {
      return <FlightsFooter flights={linkedFlights?.flight} />;
    }
  };

  return (
    <div className="package-wrapper hover:tw-bg-slate-200">
      {!fullDeal ? (
        <Loader size="small" />
      ) : (
        <>
          {renderHotelData()}
          <Divider orientation="horizontal" flexItem />
          {renderFlights()}
        </>
      )}
    </div>
  );
};

export default TourObject;
