import React from "react";
import { useSelectedResult } from "../../../contexts/SelectedResultContext";
import { useNavigate } from "react-router-dom";
import { errorMessage } from "../../../services/swalService";
import PackagesProfile from "./PackagesProfile";

const UnsavedPackagesProfile = () => {
  const { selectedResult } = useSelectedResult();
  const navigate = useNavigate();

  if (!selectedResult) {
    errorMessage("יש לבצע חיפוש קודם, הינך מועבר לעמוד חיפוש.").finally(() => {
      navigate("/p/dashboard/searchoffer");
    });
    return null;
  }

  return <PackagesProfile />;
};

export default UnsavedPackagesProfile;
