import React from "react";
import Rating from "@mui/material/Rating";
import { useTranslation } from "react-i18next";
import translateLDSDestinations from "../../services/translateLDSDestinations";
import { WiTime8 } from "react-icons/wi";
import { MdOutlineDirectionsBus } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import { useNavigate } from "react-router-dom";
import { BlueButton } from "../../Components/buttons/Buttons";

import "./SelectedPackageDetails.scss";
import { useAppContext } from "../../contexts/AppContext";
import { useSelectedResult } from "../../contexts/SelectedResultContext";

const SelectedPackageDetails = ({ fullDeal }) => {
  const { isAppLoggedIn } = useAppContext();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const getBoardDetails = (LDSboard) => {
    switch (LDSboard) {
      case "BB":
        return t("boards.BB");
      case "HB":
        return t("boards.HB");
      case "FB":
        return t("boards.FB");
      case "AI":
        return t("boards.AI");
      case "RO":
        return t("boards.RO");
      default:
        return LDSboard;
    }
  };

  const {
    hotel,
    dealFare,
    linkedFlights,
    isTransferIncluded,
    supplierDetails,
    fareDetails,
    hotelAllocation,
    ...rest
  } = fullDeal;
  const starRating = hotel?.category?.replace(/\D/g, "");
  const boardDetails = getBoardDetails(hotel?.board);
  const isAutoApprove = hotelAllocation === "true";
  const translatedName = translateLDSDestinations(hotel?.hotelCity);

  const onReturnClick = () => {
    navigate("/p/dashboard/searchoffer");
  };

  return (
    <div className="tw-flex tw-flex-col lg:tw-flex-row">
      <div className="tw-flex tw-flex-col tw-flex-1">
        <div className="tw-flex tw-flex-col">
          <div className="hotel-city">
            {`חבילת נופש ל${translatedName.COUNTRYNAME_HEB} - ${translatedName.CITYNAME_HEB}`}
          </div>
          <div className="hotel-name">
            {hotel?.hotelName}
            <Rating
              name="read-only"
              value={Number(starRating)}
              readOnly
              visuallyHidden
              size="small"
              emptyIcon={<></>}
            />
          </div>

          <div className="text-with-icon tw-flex ›">
            <SlCalender className="orange-icon tw-ml-2" size={20} />

            <div className="text">
              {hotel?.checkInDate} - {hotel?.checkOutDate} | {boardDetails}
            </div>
          </div>

          <div className="text-with-icon tw-flex ">
            <div>
              <WiTime8 className="orange-icon tw-ml-2" size={20} />
            </div>

            <div className="text">
              {hotel?.nights} {t("packages.nights")}
            </div>
          </div>

          {isTransferIncluded === "true" && (
            <div className="text-with-icon tw-flex ">
              <MdOutlineDirectionsBus
                className="orange-icon tw-ml-2"
                size={20}
              />

              <div className="text">כולל העברות</div>
            </div>
          )}
        </div>
      </div>
      <div className="tw-flex tw-flex-col">
        {isAppLoggedIn() && (
          <BlueButton
            onClick={onReturnClick}
            style={{
              justifyContent: "center",
            }}
          >
            חזרה לעמוד תוצאות
          </BlueButton>
        )}
      </div>
    </div>
  );
};

export default SelectedPackageDetails;
