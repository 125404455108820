import React, { createContext, useContext, useState, useEffect } from "react";
import { round2, useAppContext } from "./AppContext";
import { IS_DEV } from "../assets/GlobalVariables";

const CardsContext = createContext();

let EMPTY_CARD = {
  cardholdername: "",
  cardnumber: "",
  expdate: "",
  cvv: "",
  cardholderid: "",
  numberofpayments: 1,
  paymentsInterest: 0,
  amount: 0,
  amountWithInterest: 0,
  paid: false,
};

// // Test card
if (IS_DEV) {
  EMPTY_CARD = {
    paymentsInterest: 0,
    amount: 0,
    amountWithInterest: 0,
    cardholderid: "000000000",
    cardholdername: "adir alul",
    cardnumber: "4557 4304 0205 3712",
    cvv: "887",
    expdate: "06/26",
    numberofpayments: "1",
    paid: false,
  };
}

export { EMPTY_CARD };

export const CardsProvider = ({ totalPrice, children }) => {
  const [cards, setCards] = useState([
    { ...EMPTY_CARD, amount: totalPrice, amountWithInterest: totalPrice },
  ]);
  const { interestTable } = useAppContext();

  useEffect(() => {
    setCards([
      { ...EMPTY_CARD, amount: totalPrice, amountWithInterest: totalPrice },
    ]);
  }, [totalPrice]);

  function _calculateInterest(numberOfPayments) {
    try {
      if (numberOfPayments >= 0 && numberOfPayments <= 12) {
        return interestTable[numberOfPayments] / 100;
      }
      console.error("Payment Error");
    } catch (e) {
      return 0;
    }

    return 0;
  }

  const calculatePaymentsInterest = (priceToPay, numberOfPayments) => {
    const interest = _calculateInterest(parseFloat(numberOfPayments));
    return (parseFloat(priceToPay) * interest * numberOfPayments) / 12;
    // סכום * ריבית / 12* מספר החודשים שהוא משלם
  };

  const allCardsInterest = (() => {
    let totalCardsFee = 0;
    cards.forEach((currentCard) => {
      totalCardsFee +=
        parseFloat(
          calculatePaymentsInterest(
            currentCard.amount,
            currentCard.numberofpayments
          )
        ) || 0;
    });
    return totalCardsFee;
  })();

  const setCardPaid = (cardIndex) => {
    let tempCards = JSON.parse(JSON.stringify(cards));
    tempCards[cardIndex].paid = true;
    setCards(tempCards);
  };

  const updateCards = (tmpCards) => {
    for (let i = 0; i < tmpCards.length; i++) {
      const tmpCurCard = tmpCards[i];
      tmpCurCard.amount = parseFloat(tmpCurCard.amount);
      tmpCurCard.paymentInterest = calculatePaymentsInterest(
        tmpCurCard.amount,
        tmpCurCard.numberofpayments
      );
      tmpCurCard.amountWithInterest = round2(
        tmpCurCard.amount + tmpCurCard.paymentInterest
      );
    }
    setCards(tmpCards);
  };

  return (
    <CardsContext.Provider
      value={{
        cards,
        updateCards,
        setCardPaid,
        allCardsInterest,
        interestTable,
      }}
    >
      {children}
    </CardsContext.Provider>
  );
};

export const useCards = () => {
  const context = useContext(CardsContext);
  if (context === undefined) {
    throw new Error("useCards must be used within a CardsProvider");
  }
  return context;
};

export default CardsContext;
