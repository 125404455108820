import React from "react";
import DateRange from "../../../Inputs/DatesRange";
import "./HotelsFilters.css";
import "../FlightsFilters.css";
import Dropdown from "../../../Inputs/Dropdown";
import StringInput from "../../../Inputs/StringInput";
import CheckboxInput from "../../../Inputs/CheckboxInput";
import InputX from "../../../Inputs/InputX";
import { Button } from "../../../buttons/Buttons";
import DatePicker from "../../../Inputs/DatePicker";

const filterInitialState = {
  order_dates: { start: "", end: "" },
  staying_dates: { start: "", end: "" },
  hotel_name: "",
  main_contact: "",
  order_id: "",
  refundableuntil: "",
  notPaid: false,
};

const HotelsFilters = ({ orders, filterOrders }) => {
  const [filters, setFilters] = React.useState(filterInitialState);
  const clearFilters = () => {
    setFilters(filterInitialState);
    filterOrders(filterInitialState);
  };

  const onFilterChange = (key, value) => {
    setFilters({ ...filters, [key]: value });
  };

  const getOptionsFromOrders = (key) => {
    return orders?.map((order) => order[key]);
  };

  const renderDatesFilter = (key, title) => {
    return (
      <div className={"filter-input-wrapper"}>
        <div>{title}</div>
        <DateRange
          enablePast
          onChange={(startDate, endDate) =>
            onFilterChange(key, { start: startDate, end: endDate })
          }
        />
      </div>
    );
  };

  const renderDateFilter = (key, title) => {
    return (
      <div className={"filter-input-wrapper"}>
        <div>{title}</div>
        <DatePicker setValue={(val) => onFilterChange(key, val)} />
      </div>
    );
  };

  return (
    <div
      style={
        {
          // width: "calc(100% - 40px)",
          // height: "100%",
          // backgroundColor: "azul",
          // padding: "20px 20px 0 20px",
        }
      }
    >
      <div className={"flights-filters-row"}>
        {renderDatesFilter("order_dates", "תאריך הזמנה")}
        {renderDatesFilter("staying_dates", "תאריכי שהייה")}
        {renderDateFilter("refundableuntil", "ביטול עד")}
      </div>
      <div className={"flights-filters-separator"} />
      <div className={"flights-filters-row"}>
        <StringInput
          title="שם מלון"
          value={filters.hotel_name}
          onValueChange={(value) => onFilterChange("hotel_name", value)}
        />
        <StringInput
          title="איש קשר ראשי"
          value={filters.main_contact}
          onValueChange={(value) => onFilterChange("main_contact", value)}
        />
        <StringInput
          title="מספר הזמנה"
          value={filters.order_id}
          onValueChange={(value) => onFilterChange("order_id", value)}
        />
        <div
          style={{
            alignSelf: "end",
            display: "flex",
            flexDirection: "row-reverse",
            paddingBottom: "5px",
          }}
        >
          <CheckboxInput
            title="לא שולם"
            value={filters.notPaid}
            onValueChange={(value) => onFilterChange("notPaid", value)}
          />
        </div>

        {/*{renderOptionsSelect(getOptionsFromOrders("optionsKey"))}*/}
        {/*{getOptionsFromOrders("optionsKey")}*/}
        <Button
          onClick={() => filterOrders(filters)}
          style={{ alignSelf: "flex-end" }}
        >
          לחץ לסינון
        </Button>
        <div className={"flights-filters-clear"} onClick={clearFilters}>
          <InputX onClick={clearFilters} />
          <div>{"איפוס סינונים"}</div>
        </div>
      </div>
    </div>
  );
};

export default HotelsFilters;
