import React from "react";
import { useTranslation } from "react-i18next";
import "./SearchOptions.scss";
import SearchOption from "../../SearchAgent/SearchOption/searchoption";
import {
  HOTELS,
  PACKAGES,
  PLANES,
  ORGANIZED_TOURS,
  useSearch,
} from "../../../../contexts/SearchContext";

const SearchOptions = () => {
  const { t } = useTranslation();
  const { searchTab, setSearchTab } = useSearch();

  return (
    <div className="searchAgent-search-option-wrapper">
      <SearchOption
        id={HOTELS}
        selected={searchTab === HOTELS}
        title={t("searchagent.hotels")}
        action={setSearchTab}
      />
      <SearchOption
        id={PACKAGES}
        selected={searchTab === PACKAGES}
        title={t("searchagent.packages")}
        action={setSearchTab}
      />
      <SearchOption
        id={ORGANIZED_TOURS}
        selected={searchTab === ORGANIZED_TOURS}
        title={t("searchagent.organizedtours")}
        action={setSearchTab}
      />
      <SearchOption
        id={PLANES}
        selected={searchTab === PLANES}
        title={t("searchagent.planes")}
        action={setSearchTab}
        disabled
      />
    </div>
  );
};

export default SearchOptions;
